/**
 *
 * ReceptionForm14140
 *
 */


import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import {
  ON_VISIT,
  ON_CAR_ENTRY,
  ON_PROPERTY_BRINGING,
  ON_PROPERTY_REMOVAL,
  ON_PASS_MAKING,
  SERVICE,
} from 'domain/Data/RequestType/constants';
import { CREATED, ON_TERRITORY, REJECTED } from 'domain/Data/RequestStatus/constants';
import { PASSPORT } from 'domain/Data/DocumentType/constants';
import { validateCarNumber } from 'utils/validators';
import { RECEPTION_REQUESTS_FORM } from 'pages/Requests/ReceptionView/constants';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants';
import { formField } from 'domain/typeConstants/form';
import * as entities from 'domain/Data/constants';
import {
  berlinskyVisitTimeLabel,
  carModelTextVisible,
  carNumberHelperText, carNumberRequired, carNumberVisible,
  departmentCalculatedValue, departmentHelperText, departmentVisibleOptions,
  documentDetailsVisible,
  documentTypeVisible,
  fileVisible, fileLabel,
  issueReasonRequired, issueReasonVisible, issueReasonVisibleOptions,
  longtermVisible,
  materialValuesDataLabel,
  nonStandardCarNumberVisible,
  parkingPlaceCalculatedValue, parkingPlaceVisible, parkingPlaceRequired,
  parkingVisible, parkingHelperText,
  passNumberVisible,
  typeHelperText,
  visitDateRowBreakBefore,
  visitorFullNameLabel,
} from '../common/fields';
import {
  MAKING_PASS_ON_THE_CAR,
  ON_CHECK_CARD,
  ON_LOSS_OR_BREAK_OF_PASS,
  ON_RETURN_CARDS,
} from '../../../domain/Data/RequestType/constants';
import { GROUND, UNDERGROUND } from '../../../domain/Data/ParkingType/constants';
import { REQUESTS } from 'domain/Data/constants';
import { COMPANY_ARCHIVE_STATUS } from 'pages/Companies/ReceptionView/constants';
import { groupRequestVisitorsDataHelperText } from 'pages/Requests/common/fields';

export class ReceptionForm14140 extends React.PureComponent {

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'arrivalDate',
        type: 'date',
        visible: false,
        initialValue: () => null,
        calculatedValue: (values, mode) => mode === 'add' && values.type === ON_VISIT ? currentDate : null
      },
      {
        name: 'departureDate',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'edit',
        disabled: true,
      },
      {
        name: 'status',
        type: formField.SELECT,
        label: 'Статус',
        entityName: entities.REQUEST_STATUSES,
        visible: (currentValues, initialValues, mode) => mode === 'edit',
        calculatedValue: (values, mode) => {
          if (mode === 'add' || mode === 'copy') { return values.type === ON_VISIT ? ON_TERRITORY : CREATED; }

          return values.status;
        },
        visibleOptions: (currentValues, initialValues, mode, user, entities) => {
          const status = entities.get('requestStatuses').valueSeq().toJS();
          if (status && (currentValues.status === REJECTED || currentValues.status === CREATED)) {
            return status.filter(item => item.id !== ON_TERRITORY).map((item) => item.id);
          }
        },
        orderBy: 'name',
      },
      {
        name: 'type',
        type: formField.SELECT,
        label: 'Вид',
        required: true,
        entityName: entities.REQUEST_TYPES,
        initialValue: (values, mode) => {
          if (mode === 'add') {
            return ON_VISIT;
          }
          return values.type;
        },
        orderBy: 'order',
        clearErrorsOnChange: true,
        helperText: typeHelperText,
      },
      {
        type: formField.SELECT,
        entityName: entities.ACTION_CARDS,
        name: 'berlinskyActionCard',
        label: 'Действие с картой',
        visible: (values) => values.type === ON_CHECK_CARD,
      },
      {
        name: 'longterm',
        type: formField.CHECKBOX,
        label: 'Долгосрочная',
        visible: longtermVisible,
      },
      {
        type: formField.CHECKBOX,
        name: 'isGiveRemoteController',
        label: 'Выдать пульт',
        visible: (values) => new Set([ON_LOSS_OR_BREAK_OF_PASS, ON_RETURN_CARDS]).has(values.type),
      },
      {
        name: 'visitDate',
        type: formField.DATE,
        label: 'Дата',
        required: true,
        visible: (currentValues, initialValues, mode) => mode === 'view' || currentValues.type !== SERVICE,
        initialValue: currentDate,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
        disabled: (currentValues) => currentValues.type === SERVICE,
        rowBreakBefore: visitDateRowBreakBefore,
      },
      {
        type: formField.SELECT,
        entityName: entities.ISSUE_REASONS,
        name: 'issueReason',
        label: 'Причина',
        visible: issueReasonVisible,
        visibleOptions: issueReasonVisibleOptions,
        required: issueReasonRequired,
      },
      {
        name: 'expirationDate',
        type: formField.DATE,
        label: 'Дата окончания',
        required: (values) => values.longterm,
        visible: (values) => values.longterm && values.type !== SERVICE,
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return new Date(values.expirationDate) > currentDate ?
              new Date(values.expirationDate) : currentDate;
          }
          return null;
        },
        calculatedValue: (values) => values.longterm ? values.expirationDate : null,
        min: (values) => new Date(values.visitDate) < currentDate ?
          new Date(values.visitDate) : currentDate,
      },
      {
        name: 'isGroupRequest',
        type: formField.CHECKBOX,
        label: 'Групповая заявка',
        rowBreakBefore: true,
        visible: (values) => values.type === ON_VISIT || values.type === ON_CAR_ENTRY,
      },
      {
        name: 'nonstandardCarNumber',
        type: formField.CHECKBOX,
        label: 'Нестандартный № авто',
        rowBreakBefore: false,
        rowBreakAfter: false,
        visible: nonStandardCarNumberVisible,
      },
      {
        type: formField.CHECKBOX,
        name: 'informed',
        label: 'Ознакомлен',
        visible: (values) => values.type === MAKING_PASS_ON_THE_CAR || values.type === ON_PASS_MAKING || values.type === ON_VISIT,
        required: true,
        helperText: "В случаи утери карты или её поломки, Арендатор должен заплатить штраф",
      },
      {
        name: 'groupRequestVisitorsData',
        label: 'Список посетителей',
        multiline: true,
        helperText: groupRequestVisitorsDataHelperText(),
        visible: (values) => values.isGroupRequest && values.type !== SERVICE,
        required: (values) => values.isGroupRequest && values.type === ON_VISIT,
        grid: { sm: 12 },
        rowBreakBefore: true,
      },
      {
        name: 'visitorFullName',
        label: visitorFullNameLabel,
        type: formField.DADATA,
        dadataType: 'NAME',
        dadataParts: ['SURNAME', 'NAME', 'PATRONYMIC'],
        autoFocus: (currentValues, initialValues, mode) => mode === 'add',
        visible: (values) => !values.isGroupRequest && values.type !== SERVICE,
        required: (values) => !values.isGroupRequest && values.type === ON_VISIT,
        rowBreakBefore: true,
      },
      {
        name: 'berlinskyMaterialCommodityValueTypes',
        label: 'Карта/Пульт',
        type: formField.SELECT,
        multiple: true,
        entityName: entities.MATERIAL_COMMODITY_VALUE_TYPES,
        visible: (values) => values.type === ON_LOSS_OR_BREAK_OF_PASS,
      },
      {
        name: 'company',
        type: formField.AUTOCOMPLETE,
        label: 'Арендатор',
        entityName: entities.COMPANIES,
        filterBy: `!status.name.contains("${COMPANY_ARCHIVE_STATUS}") or (status = null)`,
        orderBy: 'name',
        required: true,
        numberInQueueFromEnd: 1,
        initialValue: (values, mode, user) => user.companyId,
        rowBreakAfter: (values) => values.type === ON_VISIT ||
          values.type === ON_CAR_ENTRY || values.type === ON_PASS_MAKING ||
          values.type === SERVICE,
      },
      {
        type: formField.SELECT,
        entityName: entities.TIMES,
        name: 'berlinskyTime',
        label: 'Время',
        visible: (values) => values.type === ON_PASS_MAKING || values.type === ON_CHECK_CARD,
      },
      {
        type: formField.SELECT,
        multiple: true,
        entityName: entities.ACCESS_AREAS,
        name: 'berlinskyAccessAreas',
        label: 'Зона доступа',
        visible: (values) => values.type === ON_PASS_MAKING || values.type === ON_CHECK_CARD,
      },
      {
        name: 'berlinksyAnotherTime',
        label: 'Другое',
        visible: (values) => values.type === ON_PASS_MAKING || values.type === ON_CHECK_CARD,
        grid: { sm: 12 },
      },
      {
        name: 'documentType',
        type: formField.SELECT,
        label: 'Вид документа',
        initialValue: PASSPORT,
        entityName: entities.DOCUMENT_TYPES,
        visible: documentTypeVisible,
        calculatedValue: (values) => values.isGroupRequest ? PASSPORT : values.documentType,
        rowBreakBefore: true,
      },
      {
        name: 'documentDetails',
        label: 'Серия, № документа',
        visible: documentDetailsVisible,
      },
      {
        name: 'passNumber',
        label: '№ карты',
        visible: passNumberVisible,
        initialValue: () => null,
      },
      {
        type: formField.SELECT,
        name: 'berlinskyVisitTime',
        entityName: entities.VISIT_TIMES,
        label: berlinskyVisitTimeLabel,
        visible: (values) => new Set([ON_VISIT, ON_CAR_ENTRY]).has(values.type),
      },
      {
        name: 'remoteControllerNumber',
        label: 'Пульт, ключ №',
        visible: (values) => new Set([ON_LOSS_OR_BREAK_OF_PASS, ON_CHECK_CARD]).has(values.type),
      },
      {
        name: 'carNumber',
        label: '№ автомобиля',
        required: carNumberRequired,
        visible: carNumberVisible,
        helperText: carNumberHelperText,
        normalize: (value) => value ? value.replace(' ', '') : value,
        validate: validateCarNumber,
        rowBreakBefore: true,
      },
      {
        name: 'carModelText',
        label: 'Марка автомобиля',
        visible: carModelTextVisible,
      },
      {
        name: 'parking',
        type: formField.SELECT,
        label: 'Парковка',
        entityName: entities.PARKINGS,
        visible: parkingVisible,
        rowBreakBefore: true,
        helperText: parkingHelperText,
        calculatedValue: (currentValues) => {
          if (currentValues) {
            if (currentValues.type === MAKING_PASS_ON_THE_CAR) {
              return UNDERGROUND;
            }
            if (currentValues.type === ON_PROPERTY_REMOVAL) {
              return GROUND;
            }

            return currentValues.parking;
          }

          return null;
        },
        disabled: (values) => values.type === MAKING_PASS_ON_THE_CAR,
      },
      {
        name: 'parkingPlace',
        label: '№ места',
        visible: parkingPlaceVisible,
        required: parkingPlaceRequired,
        calculatedValue: parkingPlaceCalculatedValue,
        initialValue: () => null,
      },
      {
        name: 'materialValuesData',
        label: materialValuesDataLabel,
        helperText: 'наименование, кол-во, ед.изм.',
        grid: { sm: 12 },
        required: true,
        visible: (values) => values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL,
      },
      {
        name: 'whoMeets',
        label: 'Кто встречает',
        visible: (values) => values.type === ON_VISIT,
        rowBreakBefore: true,
      },
      {
        type: formField.SELECT,
        entityName: entities.DEPARTMENTS,
        name: 'department',
        label: 'Здание',
        visible: true,
        required: true,
        calculatedValue: departmentCalculatedValue,
        helperText: departmentHelperText,
        rowBreakBefore: true,
        visibleOptions: departmentVisibleOptions,
      },
      {
        name: 'isDiscretionOfSecurity',
        label: (values) => values.type === ON_CAR_ENTRY ? 'Выбор машиноместа на усмотрение СБ' : 'На усмотрение СБ',
        type: formField.CHECKBOX,
        visible: (values) => (values.type === ON_CAR_ENTRY || values.type === MAKING_PASS_ON_THE_CAR) && values.parking !== GROUND,
      },
      {
        name: 'companyName',
        label: 'Из какой компании',
        visible: true,
        // required: true,
      },
      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
      },
      {
        name: 'managementCompanyComment',
        label: 'Комментарий УК',
        visible: (values, initialValues, mode) => mode === 'view' || mode === 'edit',
        grid: { sm: 12 },
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return null;
          }
          return values.managementCompanyComment;
        },
        required: (currentValues) => currentValues.status === REJECTED,
      },
      {
        name: 'customer',
        type: formField.SELECT,
        label: 'Заявитель',
        entityName: entities.EMPLOYEES,
        visible: (values) => values.type === SERVICE,
        rowBreakBefore: true,
      },
      {
        name: 'service',
        type: formField.SELECT,
        label: 'Категория работ',
        entityName: entities.SERVICES,
        visible: (values) => values.type === SERVICE,
        rowBreakBefore: true,
      },
      {
        name: 'whatHappened',
        label: 'Что нужно сделать',
        multiline: false,
        visible: (values) => values.type === SERVICE,
        grid: { sm: 12 },
        required: true,
      },
      {
        name: 'assignee',
        type: formField.SELECT,
        label: 'Исполнитель',
        entityName: entities.EMPLOYEES,
        orderBy: 'name',
        cached: false,
        visible: false,
        rowBreakAfter: (values) => values.type === SERVICE,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.assignee;
        },
      },
      {
        name: 'executionDate',
        type: formField.DATE,
        label: 'Дата выполнения факт',
        required: true,
        visible: false,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate
          ? new Date(initialValues.visitDate) : currentDate,
        rowBreakBefore: true,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionDate;
        },
      },
      {
        name: 'executionTerm',
        type: formField.DATE,
        label: 'Срок исполнения',
        required: true,
        visible: false,
        min: () => currentDate,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionTerm;
        },
      },
      {
        name: 'whereHappened',
        label: 'Где произошло',
        visible: (values) => values.type === SERVICE,
      },
      {
        name: 'implementationReport',
        label: 'Отчет о выполнении',
        visible: (values) => values.type === SERVICE,
        grid: { sm: 12 },
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.implementationReport;
        },
      },
      {
        name: 'file',
        label: fileLabel,
        type: formField.FILE,
        visible: fileVisible,
        rowBreakBefore: true,
        required: (values) => new Set([ON_PROPERTY_REMOVAL]).has(values.type),
      },
      {
        name: 'photo',
        label: 'Фото',
        type: formField.FILE,
        visible: (values) => values.type === ON_PASS_MAKING,
      },
    ];
  }

  getClassNames() {
    return {
      helperText: '',
    };
  }

  render() {
    const accountId = getValueFromLocalStorage(GLOBAL_USER_KEY).accountId
    return (
      <AdvancedForm
        name={RECEPTION_REQUESTS_FORM}
        entityName={REQUESTS}
        classNames={this.getClassNames()}
        fields={this.getFields()}
        useAsyncValidate
        accountId={accountId}
        {...this.props}
      />
    );
  }
}

export default ReceptionForm14140;
