/**
 *
 * AdvancedForm
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';

import {formField} from 'domain/typeConstants/form';

import Autocomplete from './Autocomplete';
import ComboBox from './ComboBox'
import Checkbox from './Checkbox';
import DateField from './DateField';
import DateTimeFieldDivided from './DateTimeFieldDivided';
import DadataField from './DadataField';
import FileField from './FileField';
import TextField from './TextField';
import SelectField from './SelectField';
import MultiAutocomplete from './MultiAutocomplete';
import EditorField from './EditorField';
import MessageField from './MessageField';
import MaskField from './MaskField';
import ComboBoxTextInput from './ComboBoxTextInput';
import SelectCheckbox from './SelectCheckbox';
import SelectGridField from './SelectGridField/index';
import DetailTableField from './DetailTable';

const styleSheet = (theme) => ({
    errorText: {
        display: 'flex',
        fontSize: 15,
        color: theme.palette.error.A700,
        padding: '8px 0',
        marginBottom: 8,
        backgroundColor: theme.palette.error[50],
    },
    errorIcon: {
        fill: theme.palette.error.A700,
        margin: '0 8px',
    },
    fieldsRowBreak: {
        width: '100%',
    },
    invisibleField: {
        display: 'none',
    },
    emptyPlace: {
        width: `100%`,
        height: '100px',
        marginLeft: 0,
    },
    paper: {
        margin: 15,
    },
    groupContainer: {
        width: '-webkit-fill-available'
    },
    groups: {
        display: 'flex',
        flexDirection: 'column',
    },
    groupTitle: {
        fontWeight: '500',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '1.2rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: 3
    },
    collapseGroup: {
        display: 'none'
    }
});

class AdvancedForm extends React.PureComponent {

    renderField(layout, field) {
        const {
            autoFocus,
            clearErrorsOnChange,
            disabled,
            format,
            grid,
            helperText,
            label,
            max,
            min,
            multiline,
            multiple,
            name,
            normalize,
            password,
            required,
            rowBreakAfter,
            rowBreakBefore,
            tooltip,
            type,
            visible,
            fileSize,
            nonstandart,
            menuItemlabel,
            addDialogPlaceholder,
            fieldName,
            readOnly,
            mask,
            actionButtons,
        } = field;

        const {classes, currentValues, initialValues, mode, classNames, user} = this.props;
        const xs = grid && grid.xs ? grid.xs : 12;
        const sm = grid && grid.sm ? grid.sm : 6;

        const isDisabled = disabled === true ||
            (typeof disabled === 'function' && disabled(currentValues, initialValues, mode, user));

        const isVisible = visible === true || visible === undefined ||
            visible === null || (typeof visible === 'function' && visible(currentValues, initialValues, mode, user));

        const isRequired = isVisible && (required === true ||
            (typeof required === 'function' && required(currentValues, initialValues, mode, user)));

        const isNonStandart = nonstandart === true ||
            (typeof nonstandart === 'function' && nonstandart(currentValues, initialValues, mode, user));

        const hasAutoFocus = autoFocus === true ||
            (typeof autoFocus === 'function' && autoFocus(currentValues, initialValues, mode, user));

        const isRowBreakBefore = rowBreakBefore === true ||
            (typeof rowBreakBefore === 'function' && rowBreakBefore(currentValues, initialValues, mode, user));

        const isRowBreakAfter = rowBreakAfter === true ||
            (typeof rowBreakAfter === 'function' && rowBreakAfter(currentValues, initialValues, mode, user));

        const getType = (typeof type === 'function' && type(currentValues)) || type;

        const getFileSize = typeof fileSize === 'function'
            ? fileSize(currentValues, initialValues, mode, user)
            : fileSize;

        const getHelperText = typeof helperText === 'function'
            ? helperText(currentValues, initialValues, mode, user)
            : helperText;

        const getMask = typeof mask === 'function'
            ? mask(currentValues, initialValues, mode, user)
            : mask;

        const getLabelText = typeof label === 'function'
            ? label(currentValues, initialValues, mode, user)
            : label;

        const getTooltipText = typeof tooltip === 'function'
            ? tooltip(currentValues, initialValues, mode, user)
            : tooltip;

        if (isRowBreakBefore) {
            layout.push(<div className={classes.fieldsRowBreak} key={`rowBreakBefore-${name}`}/>);
        }

        const gridClassName = classnames({
            [classes.invisibleField]: !isVisible,
        });

        const fieldProps = {
            label: getLabelText,
            name,
            required: isRequired,
            disabled: isDisabled,
            tooltip: getTooltipText,
            clearErrorsOnChange,
            normalize,
        };
        if (mode === 'view') {
            fieldProps.readOnly = true;
        }

        switch (getType) {
            case formField.DATETIME: {
                if (min) {
                    fieldProps.minDate = min(currentValues, initialValues, mode);
                }
                if (max) {
                    fieldProps.maxDate = max(currentValues, initialValues, mode);
                }

                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <DateField
                            helperText={getHelperText}
                            classNames={classNames}
                            fullWidth
                            formatmask="DD.MM.YYYY HH:mm"
                            {...fieldProps}
                        />
                    </Grid>
                );
                break;
            }

            case formField.DATE: {
                if (min) {
                    fieldProps.minDate = min(currentValues, initialValues, mode);
                }
                if (max) {
                    fieldProps.maxDate = max(currentValues, initialValues, mode);
                }

                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <DateField
                            helperText={getHelperText}
                            classNames={classNames}
                            fullWidth
                            formatmask="DD.MM.YYYY"
                            {...fieldProps}
                        />
                    </Grid>
                );
                break;
            }

            case formField.DATETIME_DIVIDED: {

                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <DateTimeFieldDivided
                            helperText={getHelperText}
                            classNames={classNames}
                            fullWidth
                            formatmask="DD.MM.YYYY HH:mm"
                            {...fieldProps}
                        />
                    </Grid>
                );
                break;
            }

            // case formField.DADATA:
            //   layout.push(
            //     <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
            //       <DadataField
            //         helperText={getHelperText}
            //         fullWidth
            //         {...fieldProps} />
            //     </Grid>
            //   );
            //   break;

            case formField.SELECT:
                fieldProps.multiple = multiple;
                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <SelectField
                            fullWidth
                            helperText={getHelperText}
                            classNames={classNames}
                            fieldName={fieldName}
                            {...fieldProps}
                        />
                    </Grid>
                );
                break;

            case formField.SELECT_GRID:
                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <SelectGridField
                            autoFocus={hasAutoFocus}
                            helperText={getHelperText}
                            classNames={classNames}
                            fullWidth
                            entityName={field.entityName}
                            {...fieldProps}
                        />
                    </Grid>
                );
                break;

            case 'table':
                let _actionButtons = []
                if (actionButtons) {
                    _actionButtons = actionButtons.map(item => {
                        const disabled = typeof item.disabled === 'function' ? item.disabled(currentValues) : item.disabled
                        return {...item, disabled}
                    })
                }

                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <DetailTableField
                            autoFocus={hasAutoFocus}
                            helperText={getHelperText}
                            classNames={classNames}
                            fullWidth
                            entityName={field.entityName}
                            actionButtons={_actionButtons}
                            {...fieldProps}
                        />
                    </Grid>
                );
                break;


            case formField.COMBOBOX:
                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <ComboBox
                            fullWidth
                            helperText={getHelperText}
                            classNames={classNames}
                            {...fieldProps}
                        />
                    </Grid>
                );
                break;

            case formField.COMBOBOX_TEXT_INPUT:
                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <ComboBoxTextInput
                            fullWidth
                            helperText={getHelperText}
                            classNames={classNames}
                            menuItemlabel={menuItemlabel}
                            addDialogPlaceholder={addDialogPlaceholder}
                            {...fieldProps}
                        />
                    </Grid>
                );
                break;

            case formField.EDITOR:
                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <EditorField
                            autoFocus={hasAutoFocus}
                            helperText={getHelperText}
                            classNames={classNames}
                            type={format}
                            fullWidth
                            rows={1}
                            rowsMax={8}
                            {...fieldProps}
                        />
                    </Grid>
                );
                break;

            case formField.AUTOCOMPLETE:
                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <Autocomplete
                            fullWidth
                            entityName={field.entityName}
                            helperText={getHelperText}
                            classNames={classNames}
                            {...fieldProps}
                        />
                    </Grid>
                );
                break;

            case formField.MULTI_AUTOCOMPLETE:
                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <MultiAutocomplete fullWidth entityName={field.entityName}
                                           initialValues={initialValues} {...fieldProps} />
                    </Grid>,
                );
                break;

            case formField.CHECKBOX:
                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <Checkbox
                            helperText={getHelperText}
                            helperTextClassName={classNames}
                            {...fieldProps}
                        />
                    </Grid>
                );
                break;

            case formField.SELECT_CHECKBOX:
                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <SelectCheckbox
                            fullWidth
                            helperText={getHelperText}
                            classNames={classNames}
                            {...fieldProps}
                        />
                    </Grid>
                );
                break;

            case formField.FILE:
            case formField.MULTIPLE_FILE:
                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <FileField
                            helperText={getHelperText}
                            classNames={classNames}
                            fullWidth
                            fileSize={getFileSize}
                            {...fieldProps}
                        />
                    </Grid>
                );
                break;

            case formField.EMPTY_BLOCK:
                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <div></div>
                    </Grid>
                );
                break;

            case formField.MESSAGE:
                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <MessageField
                            classNames={classNames}
                            fullWidth
                            rows={1}
                            rowsMax={8}
                            {...fieldProps}
                        />
                    </Grid>
                );
                break;

            case 'carNumber':
                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <MaskField
                            classNames={classNames}
                            helperText={getHelperText}
                            nonstandart={isNonStandart}
                            {...fieldProps}
                        />
                    </Grid>
                );
                break;

            default: {
                if (password) {
                    fieldProps.type = 'password';
                }

                const isMultiline = multiline === true ||
                    (typeof multiline === 'function' && multiline(currentValues, initialValues, mode, user));

                layout.push(
                    <Grid key={name} className={gridClassName} item xs={xs} sm={sm}>
                        <TextField
                            autoFocus={hasAutoFocus}
                            helperText={getHelperText}
                            classNames={classNames}
                            multiline={isMultiline}
                            readOnly={readOnly}
                            type={format}
                            fullWidth
                            rows={1}
                            rowsMax={8}
                            mask={getMask}
                            {...fieldProps}
                        />
                    </Grid>
                );
                break;
            }
        }

        if (isRowBreakAfter) {
            layout.push(<div className={classes.fieldsRowBreak} key={`rowBreakAfter-${name}`}/>);
        }

        return layout;
    }

    handleGroupClick = (name) => {
        const {groups, setGroups} = this.props
        const newGroups = groups.map(group => group.name === name ? {...group, expand: !group.expand} : group)
        if (setGroups) setGroups(newGroups)
    }

    renderGroup(group) {
        const {fields, currentValues, classes} = this.props
        return (
            <div key={group.name} className={classes.groupContainer}>
                <Grid container>
                    < IconButton
                        onClick={() => this.handleGroupClick(group.name)}
                    >
                        {group.expand
                            ? <ExpandLess/>
                            : <ExpandMore/>
                        }
                    </IconButton>
                    <Typography
                        type="subheading" className={classes.groupTitle}>
                        {group.title}
                    </Typography>
                </Grid>

                <div className={classnames(classes.paper, !group.expand && classes.collapseGroup)}>
                    <Grid container spacing={8}>
                        {group.type === 'fields' && group.items && currentValues
                            && fields.filter(field => group.items.includes(field.name))
                                .reduce((layout, field) => this.renderField(layout, field), [])}
                    </Grid>
                </div>
            </div>
        )
    }

    getValuesFields = () => {
        const {classes, groups, error, currentValues, fields} = this.props;
        return (
            <div>
                {
                    error &&
                    <Typography type="subheading" component="h3" className={classes.errorText}>
                        <Icon className={classes.errorIcon}>error</Icon>
                        {error}
                    </Typography>
                }
                {groups.length > 0 ?
                    <div className={classes.paper}>
                        <Grid container spacing={8} className={classes.groups}>
                            {groups.map((group) => this.renderGroup(group))}
                        </Grid>
                    </div>
                    :
                    <div className={classes.paper}>
                        <Grid container spacing={8}>
                            {currentValues && fields.reduce((layout, field) => this.renderField(layout, field), [])}
                        </Grid>
                    </div>
                }
            </div>
        )
    }

    getAdditionalValuesFields = (fields) => {
        const {currentValues} = this.props;
        return (
            <div style={{padding: '4px'}}>
                <Grid container spacing={8}>
                    {currentValues && fields.reduce((layout, field) => this.renderField(layout, field), [])}
                </Grid>
            </div>
        )
    }

    render() {
        const {handleSubmit} = this.props;

        return (
            <form onSubmit={handleSubmit}>
                {this.getValuesFields()}
            </form>
        )
    }
}

AdvancedForm.propTypes = {
    classes: PropTypes.object,
    header: PropTypes.node,
    footer: PropTypes.node,
    currentValues: PropTypes.object,
    initialValues: PropTypes.object,
    mode: PropTypes.oneOf(['add', 'edit', 'view', 'copy', 'delete']),
    user: PropTypes.object,
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    classNames: PropTypes.object,
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            type: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.func,
            ]),
            label: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.func,
            ]),
            helperText: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.func,
            ]),
            autoFocus: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.func,
            ]),
            visible: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.func,
            ]),
            multiline: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.func,
            ]),
            required: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.func,
            ]),
            disabled: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.func,
            ]),
            tooltip: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.func,
            ]),

            fileSize: PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.func,
            ]),

            initialValue: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
                PropTypes.object,
                PropTypes.func,
            ]),
            calculatedValue: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
                PropTypes.object,
                PropTypes.func,
            ]),
            grid: PropTypes.object,
            clearErrorsOnChange: PropTypes.bool,
            min: PropTypes.func,
            max: PropTypes.func,
            rowBreakBefore: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.func,
            ]),
            rowBreakAfter: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.func,
            ]),
        })
    ).isRequired,
};

export default withStyles(styleSheet)(AdvancedForm);
